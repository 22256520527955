(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('mmmApp') //
        .constant('_', window._) // lodash support
        .constant('jQuery', $) // jQuery support
        .constant('VERSION', "Version 1.3.35-SNAPSHOT | Built at 12 Dec 2024 13:35:30") //
        .constant('DEBUG_INFO_ENABLED', false) //
        .constant('BUILD_TIMESTAMP', 1734006945397) //
;
})();
